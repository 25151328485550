<template>
    <b-modal
      id="room-edit-modal"
      title="Room Edit Modal"
      centered
      hide-footer
      size="lg"
      @hidden="reset"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template #modal-title>
        <h2 class="m-0">Room Edit</h2>
      </template>
      <validation-observer ref="roomEditFormValidation">
        <b-form @submit.prevent="validationForm">
          <b-form-group label-for="name">
            <template #label>
              Room Name <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="name"
              v-model="name"
              v-restrict="regex"
              placeholder="Room Name"
              required
              @keydown="checkMaxLength($event,'name', 200)"
            />
          </b-form-group>

          <b-form-group label-for="amount">
            <template #label>
              Amount <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="amount"
              v-model="amount"
              placeholder="Amount"
              required
              type="number"
              min="1"
              step="1"
          />
          <b-form-invalid-feedback v-if="!amountState">
            Please enter a valid positive integer.
          </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="description">
            <template #label>
              Description
            </template>
            <quill-editor id="description" v-model="description"  />
          </b-form-group>

          <b-form-group label-for="hotel">
          <template #label>
            Hotel <span class="text-danger">*</span>
          </template>
          <validation-provider
                #default="{ errors }"
                name="Hotel"
                rules="required" >  
          <v-select label="name" v-model="hotel" placeholder="Select Hotel" :options="hotels" :reduce="option => option.id" autoscroll :clearable="true"></v-select>
          <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>             
        </b-form-group>

          
          <b-form-group label-for="status">
            <template #label>
              Status <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="status"
              v-model="status"
              :options="options"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group class="mt-2 text-right">
            <b-button
              type="submit"
              variant="info"
              pill
              class="mr-1"
            >
              Update
            </b-button>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor
  },
  mixins: [util],
  props: {
    room: Object,
  },
  data() {
    return {
      required,
      name: "",
      hotel: "",
      hotels: [],
      amount: "",
      description: "",
      status: "",
      options: [
        {  value: '', text: '-- Select --' },
        {  value: true, text: 'Enable' },
        {  value: false, text: 'Disable' },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    const res_hotels = await this.getHotels();
    this.hotels = res_hotels.data;

    if (this.room) {
      this.name = this.room.name;
      this.hotel = this.room.hotel;
      this.amount = this.room.amount;
      this.description = this.room.description;
      this.status = this.room.status;
    }
  },
  methods: {
    ...mapActions({
      updateRoom: "appData/updateRoom",
      getHotels: "appData/getHotelsPublic",

    }),
    async validationForm() {
        const success = await this.$refs.roomEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      checkMaxLength(event, fieldName, maxLength) {
            if (
              event.target.value.length >= maxLength &&
              event.key !== "Backspace" &&
              event.key !== "Delete"
            ) {
              event.preventDefault();
              this[fieldName] = event.target.value.slice(0, maxLength);
            }
          },
    async submit() {
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('hotel', this.hotel);
      formData.append('amount', this.amount);
      formData.append('description', this.description);
      formData.append('status', this.status);
      formData.append('updated_by', this.getLoggedInUser.id);

      try {
        const res = await this.updateRoom({
          payload: formData,
          pk: this.room.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Room updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("room-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.description = "";
      this.amount = "";
      this.hotel = null;
      this.status = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    amountState() {
      return this.amount !== "" && Number.isInteger(Number(this.amount)) && Number(this.amount) > 0;
    },
  },
};
</script>

<style></style>
